/* eslint-disable linebreak-style */
/* eslint-disable quotes */
const menuBtn = document.querySelector(".header__menu-btn");
const menuCloseBtn = document.querySelector(".sidemenu__close-btn");
const menu = document.querySelector(".sidemenu");

menuBtn.addEventListener("click", () => {
  menu.classList.add("active");
});

menuCloseBtn.addEventListener("click", () => {
  menu.classList.remove("active");
});
